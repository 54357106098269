
.carousel-slider-image{
    width: 100%;
    max-height: 550px;
    object-fit: contain;
}
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -46px !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}
.carousel-indicators [data-bs-target] {
    width: 10px !important;
    height: 10px !important;
    background-color: #25372b !important;
    border-radius: 50% !important;
    text-indent: unset !important; 
    background-clip: unset !important;
    border:none !important;
    border-top: none !important;
    border-bottom:none !important;
}