
footer{
    background-color: #191919;
    color: #fff;
    padding: 40px 0;
    margin-top: auto;
}
footer h3{
    font-size: 19px;
    padding-bottom: 10px;
    width: fit-content;
    border-bottom: 1px solid #fff;
}
footer .footer-contactus p{
    font-size: 20px;
}
footer .footer-contactus .social-icons{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
footer .footer-contactus .social-icons a{
    display: flex;
    font-size: 24px;
    justify-content: center;
    align-items: center;
    width: 50px;height: 50px;
    border: none;
    background-color: #313131;
    color: #fff !important;
    transition: 0.3s;
}
footer .footer-contactus .social-icons a:nth-child(1):hover{
    background-color: #34A853;
    cursor: pointer;
}
footer .footer-contactus .social-icons a:nth-child(2):hover{
    background-color: #BB001B;
    cursor: pointer;
}
footer .footer-contactus .social-icons a:nth-child(3):hover{
    background-color: #045e94;
    cursor: pointer;
}
footer .footer-contactus .social-icons a:nth-child(4):hover{
    background-color: #fff;
    color: #045e94 !important;
    cursor: pointer;
}
.payment-icons{
    display: flex;
    gap: 15px;
}